<template>
  <div>
    <div v-if="noVod">
      <VodStatusIndicator :status="matchData.vod_status" variant="long" />
      <p>Use the form to upload video for the whole match</p>
      <SubmitMatchVod :match-id="matchId" />
    </div>

    <div v-else-if="videoInProgress">
      <VodStatusIndicator :status="matchData.vod_status" variant="long" />
    </div>

    <InfoAlert v-else-if="!fetchVideoUrlParams"> Please, select a round from the timeline above </InfoAlert>

    <ApiLoadingController v-else :fetch="fetchMapData" :params="fetchMapDataParams">
      <template #default="{ data: mapData }">
        <ApiLoadingController :fetch="fetchVideoUrl" :params="fetchVideoUrlParams">
          <template #default="{ data: videoUrl }">
            <div class="content">
              <VodReplay
                class="main"
                :current-time.sync="currentTime"
                :playback-rate.sync="playbackRate"
                :playing.sync="playing"
                :url="videoUrl"
              />
              <Killfeed
                class="side"
                :agents="agentsById"
                :events="eventsForKillfeed(mapData, roundNum)"
                :match-players="mapData.matchPlayers"
                :players="mapData.players"
                :round-teams="mapData.roundTeams"
                :rounds="mapData.rounds"
                :weapons="weaponsById"
                @select="currentTime = $event.round_time_millis"
              />
              <Timeline
                class="foot"
                :current-time.sync="currentTime"
                :data="mapData"
                :events="eventsForKillfeed(mapData, roundNum)"
                :playable="true"
                :playback-rate.sync="playbackRate"
                :playing.sync="playing"
                :round-duration="roundDuration(mapData, roundNum)"
                :selected="selectedForTimeline(mapData, roundNum)"
                :selectable="false"
              />
            </div>
          </template>
          <template #no-data />
        </ApiLoadingController>
      </template>
    </ApiLoadingController>
  </div>
</template>

<script>
import px from 'vue-types'
import { mapActions, mapGetters } from 'vuex'

import { getMatchRoundInfo } from '../../api/matches/rounds.js'
import ApiLoadingController from '../../components/controllers/ApiLoadingController.vue'
import InfoAlert from '../../components/generic/InfoAlert.vue'
import Killfeed from '../../components/Map/components/Killfeed.vue'
import Timeline from '../../components/Map/components/Timeline.vue'
import SubmitMatchVod from '../../components/Match.old/SubmitMatchVod.vue'
import VodReplay from '../../components/replay/VodReplay.vue'
import VodStatusIndicator from '../../components/UI/VodStatusIndicator.vue'
import vodStatus, { NO_VIDEO, UNKNOWN_VOD_STATUS } from '../../utils/vodStatus.js'

export default {
  name: 'MatchVideoPage',
  components: { Timeline, Killfeed, VodReplay, SubmitMatchVod, VodStatusIndicator, InfoAlert, ApiLoadingController },
  props: {
    matchId: px.string.isRequired,
    roundNum: px.number,
    matchData: px.object,
  },
  data: () => ({
    currentTime: 0,
    playbackRate: 1,
    playing: false,
  }),
  computed: {
    ...mapGetters({
      agentsById: 'static/agentsById',
      weaponsById: 'static/weaponsById',
    }),
    fetchMapDataParams() {
      if (!this.matchId) return null
      return { matchId: this.matchId }
    },
    fetchVideoUrlParams() {
      if (!this.matchId || this.roundNum == null) return null
      return { matchId: this.matchId, roundNum: this.roundNum }
    },
    noVod() {
      return !this.matchData.vod_status || this.matchData.vod_status === 'failed'
    },
    videoInProgress() {
      return this.matchData.vod_status && this.matchData.vod_status !== 'succeeded'
    },
    vodStatusObj() {
      return this.matchData.vod_status ? vodStatus[this.matchData.vod_status] || UNKNOWN_VOD_STATUS : NO_VIDEO
    },
    vodIcon() {
      return this.vodStatusObj.icon
    },
    vodLabel() {
      return this.vodStatusObj.label
    },
  },
  methods: {
    ...mapActions({
      fetchMapAnalyticsData: 'map/fetchMapAnalyticsData',
    }),

    eventsForKillfeed(mapData, roundNum) {
      const filter = e => mapData.rounds[e.round_id].round_num === roundNum
      return Object.freeze(
        [mapData.kills.filter(filter), mapData.plants.filter(filter), mapData.defuses.filter(filter)].flat()
      )
    },

    async fetchMapData({ matchId }, config) {
      return this.fetchMapAnalyticsData({
        matches: [matchId],
        config,
        exclude: {
          ml: true,
          orbs: true,
          utilities: true,
        },
      })
    },
    async fetchVideoUrl({ matchId, roundNum }, config) {
      const data = await getMatchRoundInfo(matchId, roundNum, config)
      return data.replay_url
    },

    roundDuration(mapData, roundNum) {
      return Object.values(mapData.rounds).find(round => round.round_num === roundNum).round_duration_millis
    },

    selectedForTimeline(mapData, roundNum) {
      const round = Object.values(mapData.rounds).find(round => round.round_num === roundNum)
      return Object.freeze({
        selected: {
          rounds: {
            [round.id]: true,
          },
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.content {
  display: grid;
  grid-template: 'main side' 'foot side';
  grid-template-columns: 1fr auto;

  .main {
    grid-area: main;
  }

  .side {
    grid-area: side;
  }

  .foot {
    grid-area: foot;
  }
}
</style>
